export default {
  ingredients: [
    { value: "1", type: "solid", group: "sugar", label: "Açúcar" },
    { value: "2", type: "solid", group: "starch", label: "Amido de milho" },
    { value: "3", type: "solid", group: "rice", label: "Arroz crú" },
    { value: "4", type: "solid", group: "oats", label: "Aveia" },
    {
      value: "5",
      type: "solid",
      group: "bicarbonate",
      label: "Bicarbonato de sódio, fermento químico e biológico",
    },
    { value: "6", type: "solid", group: "coffe", label: "Café em pó" },
    {
      value: "7",
      type: "solid",
      group: "nuts",
      label: "Castanhas, amêndoas, nozes",
    },
    { value: "8", type: "solid", group: "chocolate", label: "Chocolate em pó" },
    { value: "9", type: "solid", group: "coco", label: "Coco ralado fresco" },
    { value: "10", type: "solid", group: "wheat", label: "Farinha de trigo" },
    { value: "11", type: "solid", group: "fuba", label: "Fubá" },
    {
      value: "12",
      type: "liquid",
      group: "liquid",
      label: "Líquidos (água, leite, bebidas, iogurtes, óleos e etc)",
    },
    {
      value: "13",
      type: "solid",
      group: "fats",
      label: "Manteiga, banha ou gordura vegetal",
    },
    { value: "14", type: "liquid", group: "honey", label: "Mel" },
    {
      value: "15",
      type: "solid",
      group: "peanutb",
      label: "Pasta de amendoim/ manteiga de amendoim",
    },
    {
      value: "16",
      type: "solid",
      group: "powder",
      label: "Polvilho doce e azedo",
    },
    { value: "17", type: "solid", group: "salt", label: "Sal comum" },
    { value: "18", type: "solid", group: "cheese", label: "Queijo ralado" },
  ],
  solidMeasures: [
    { value: "1", label: "colher de chá" },
    { value: "2", label: "colher de sobremesa" },
    { value: "3", label: "colher de sopa" },
    { value: "4", label: "copo americano" },
    { value: "5", label: "xícara de chá" },
    { value: "6", label: "onça (oz)" },
    { value: "7", label: "grama (g)" },
    { value: "8", label: "pound / libra (lb)" },
    { value: "9", label: "quilograma (kg)" },
  ],

  liquidMeasures: [
    { value: "1", label: "colher de chá" },
    { value: "2", label: "colher de sobremesa" },
    { value: "3", label: "colher de sopa" },
    { value: "4", label: "copo americano" },
    { value: "5", label: "xícara de chá" },
    { value: "6", label: "onça fluida (fl oz)" },
    { value: "7", label: "mililitros (ml)" },
    { value: "8", label: "quart" },
    { value: "9", label: "litros (l)" },
  ],
}
